import React from "react"
import Header from "./Header"
import "./styles.scss"

const Layout = ({ children }) => (
  <div className="container my-4">
    <Header />
    <div>{children}</div>
  </div>
)

export default Layout
