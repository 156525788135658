import React from "react"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import ImageCarousel from "../components/ImageCarousel"
import { useMediaQuery } from 'react-responsive'

import image1 from "../images/home/chair1.jpg"
import image2 from "../images/home/chair2.jpg"
import image3 from "../images/home/chair3.jpg"
import image4 from "../images/home/stool1.jpg"
import image5 from "../images/home/collection1.jpg"


/* compressed:  https://tinypng.com/ */
const images = [
  {
    src: image1,
    caption: 'Pacific Chairs: Walnut & Maple'
  },
  {
    src: image2,
    caption: 'Pacific Chair: Oregon Black Walnut'
  },
  {
    src: image3,
    caption: 'Pacific Chair: Oregon Black Walnut'
  },
  {
    src: image4,
    caption: 'Walnut Stool'
  },
  {
    src: image5,
    caption: 'Seating and Pacific Coffee Tables'
  },
];

const IndexPage = () => {
  // const isMobileDevice = useMediaQuery({
  //   /* breakpoints: http://stephen.io/mediaqueries/iphonex/ */
  //   query: '(min-device-width: 375px)'
  // })
  // // isMobileDevice ? images.push(desktopChairs) : images.unshift(desktopChairs)

  return <Layout>
    <SEO title="Erethworks" />
    <ImageCarousel items={images} />
  </Layout>
}

export default IndexPage
